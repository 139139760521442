import React, { useEffect, useState } from "react";
import "./home.css";
import Hero from "../Components/Hero";
import ServicesHome from "../Components/ServicesHome";
import WhyChooseUs from "../Components/WhyChooseUs";
import Clients from "../Components/Clients";
import Location from "../Components/Location";
import MarketingConsulting from "../Components/MarketingConsulting";
import HeroImage from "../Images/imageS.png";
import { Link } from "react-router-dom";
import OwnerImage from "../Images/OwnerImageNew.png";
import { BarLoader } from "react-spinners";

export default function Home() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);
  return (
    <>
      {loading ? (
        <section
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "85vh",
          }}
        >
          {" "}
          <BarLoader
            color={"#f25a2c"}
            loading={loading}
            width={200}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </section>
      ) : (
        <section>
          {" "}
          <Hero
            subHeading="WELCOME"
            Heading_Blue="Marketing Solution for"
            Heading_Orange="Machine Manufacturers"
            Sup_Para="In today's digital landscape, staying ahead in the machinery
        manufacturing industry requires more than just exceptional
        products; it demands a robust online presence and effective
        marketing strategies."
            Image={HeroImage}
          />
          <ServicesHome />
          <MarketingConsulting />
          <WhyChooseUs />
          <Clients />
          <div className="mainDivisionFromOwner_Home">
            {" "}
            <div className="fromOwnerDiv">
              <div className="contentDiv_fromOwner">
                <h3>FROM OWNER</h3>
                <h2>Jitendra Jangid</h2>
                <h1>Owner of X360 Marketing</h1>
                <h4>Social Media Links</h4>
                <div className="socialMediaHandles_AboutUs">
                  <a
                    target="blank"
                    href={"https://www.instagram.com/royal_jangid492/"}
                  >
                    {" "}
                    <i className="fa-brands fa-instagram fa-xl"></i>{" "}
                  </a>
                  <a
                    href="https://www.instagram.com/royal_jangid492/"
                    target="blank"
                    to={"/"}
                  >
                    {" "}
                    <i className="fa-brands fa-facebook fa-xl"></i>{" "}
                  </a>
                  <a href="https://wa.me/917849912491" target="blank" to={"/"}>
                    {" "}
                    <i className="fa-brands fa-whatsapp fa-xl"></i>{" "}
                  </a>
                  <a
                    href="https://www.linkedin.com/in/jitendra-jangid-65371a1a1/"
                    target="blank"
                    to={"/"}
                  >
                    {" "}
                    <i className="fa-brands fa-linkedin fa-xl"></i>{" "}
                  </a>
                  <Link to="/">
                    {" "}
                    <i className="fa-brands fa-pinterest fa-xl"></i>{" "}
                  </Link>
                  <Link to={"/"}>
                    {" "}
                    <i className="fa-brands fa-youtube fa-xl"></i>{" "}
                  </Link>
                </div>
                <p>
                  At X360 Marketing, we understand the unique challenges and
                  opportunities that machinery manufacturers face in the digital
                  age. That's why we offer specialized digital marketing
                  solutions tailored specifically to meet the needs of this
                  industry. With our comprehensive services, machinery
                  manufacturers can enhance their online presence, drive
                  targeted traffic, and generate quality leads.
                  <br /> <br />
                  Our strategic approach to digital marketing for machinery
                  manufacturers includes a combination of search engine
                  optimization
                  {" (SEO)"}, content marketing, social media management, and
                  targeted advertising. By leveraging these tactics, we help our
                  clients increase brand awareness, attract potential customers,
                  and ultimately, drive sales.
                </p>
              </div>
              <div className="imageDiv_fromOwner">
                <img src={OwnerImage} alt="Owner of X360 Marketing" />
              </div>
            </div>
          </div>
          <Location />
        </section>
      )}
    </>
  );
}
