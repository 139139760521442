import React from "react";
import "./servicesHome.css";
import HeadingPlusPara from "./HeadingPlusPara";
import digitalMarketingImage from "../Images/digitalmarketingService.png";
import websiteDesignImage from "../Images/websitedesignServices.png";
import seoImage from "../Images/seoServices.png";
import otherServiceImage from "../Images/otherServices.png";
import { Link } from "react-router-dom";

export default function ServicesHome() {
  return (
    <>
      <section className="ServicesSection_Home">
        <HeadingPlusPara
          Heading="Our Services"
          SupportiveParagraph="Elevate Your Online Presence with X360 Marketing: Tailored Solutions for Maximum Digital Growth and Success."
        />
        <div className="mainDivision_ServicesSection">
          <div className="digitalMarketingServicesDiv servicesDiv">
            <img src={digitalMarketingImage} alt="Digital Marketing Service" />
            <h2>Digital Marketing Service</h2>
            <p>
              Digital marketing boosts visibility, engages audiences, and drives
              online success.
            </p>
            <div className="btnDiv_Services">
              <Link
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                to={"/services/digitalmarketing"}
              >
                <button className="learnMore_BTN_Services">Learn More</button>
              </Link>
            </div>
          </div>
          <div className="allServicesDiv servicesDiv">
            <img src={otherServiceImage} alt="x300 Marketing all Services" />
            <h2>Content Marketing</h2>
            <p>
              Engage, attract, and convert with strategic content marketing
              solutions.{" "}
            </p>
            <div className="btnDiv_Services">
              <Link
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                to={"/services/contentmarketing"}
              >
                <button className="learnMore_BTN_Services">Learn More</button>
              </Link>
            </div>
          </div>

          <div className="seoServicesDiv servicesDiv">
            <img
              src={seoImage}
              alt="Search Engine Optimization (SEO) Service"
            />
            <h2>Search Engine Optimization</h2>
            <p>
              SEO improves visibility, attracts traffic, and boosts online
              rankings effectively.
            </p>
            <div className="btnDiv_Services">
              <Link
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                to={"/services/searchengineoptimization"}
              >
                <button className="learnMore_BTN_Services">Learn More</button>
              </Link>
            </div>
          </div>
          <div className="socialMediaMarketingServicesDiv servicesDiv">
            <img
              src={websiteDesignImage}
              alt="Social Media Marketing Service"
            />
            <h2>Website Designing Services</h2>
            <p>
              Create stunning, user-friendly websites that drive engagement and
              conversions.{" "}
            </p>
            <div className="btnDiv_Services">
              <Link
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                to={"/services/websitedesigning"}
              >
                <button className="learnMore_BTN_Services">Learn More</button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
