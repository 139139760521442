import React, { useState } from "react";
import "./navbar.css";
import companyLogo from "../Images/mainLogo.png";
import { Link } from "react-router-dom";
import dataPro from "../Data/ServicesData";

export default function Navbar() {
  const [classNameNavIndustry, setClassNameNavIndustry] =
    useState("navMenuDivHide");

  const [classNameMegaMenu, setClassNameMegaMenu] = useState(
    "marketingSolutions_MegaMenu_Hide"
  );

  // Mobile Menu

  const [classNameMobileMenu, setClassNameMobileMenu] = useState(
    "mobileMenu_Hide_Navbar"
  );

  const [classNameMobileMenuIcon, setClassNameMobileMenuIcon] = useState(
    "fa-solid fa-bars fa-sm fa-xl"
  );

  // End of Mobile Menu

  window.onscroll = function scrolledWindow() {
    if (classNameNavIndustry === "navMenuDivView") {
      setClassNameNavIndustry("navMenuDivHide");
    }
    if (classNameMegaMenu === "marketingSolutions_MegaMenu_View") {
      setClassNameMegaMenu("marketingSolutions_MegaMenu_Hide");
    }
  };

  return (
    <>
      <section className="NavbarSection">
        <div className="mainDivision_NavbarSection">
          <div className="logoDiv_Navbar">
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              to={"/"}
            >
              <img src={companyLogo} alt="X360 Marketing Logo" />
            </Link>
          </div>
          <div className="navbarDiv_Navbar">
            <ul>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={"/"}
                >
                  X360 Marketing
                </Link>
              </li>
              <li
                onClick={() => {
                  if (
                    classNameMegaMenu === "marketingSolutions_MegaMenu_Hide" ||
                    classNameNavIndustry === "navMenuDivView"
                  ) {
                    setClassNameMegaMenu("marketingSolutions_MegaMenu_View");
                    setClassNameNavIndustry("navMenuDivHide");
                    window.addEventListener("click", function (e) {
                      if (
                        e.target !== document.getElementById("megaMenu") &&
                        e.target !==
                          document.getElementById("listItem_MegaMenu")
                      ) {
                        setClassNameMegaMenu(
                          "marketingSolutions_MegaMenu_Hide"
                        );
                      }
                    });
                  } else {
                    setClassNameMegaMenu("marketingSolutions_MegaMenu_Hide");
                    window.removeEventListener("click", function (e) {});
                  }
                }}
              >
                <Link id="listItem_MegaMenu">
                  Marketing Solutions<i className="fa-solid fa-angle-down"></i>
                </Link>

                {/* Marketing Solutions */}
                <div id="megaMenu" className={classNameMegaMenu}>
                  {dataPro.map((item) => {
                    return (
                      <>
                        <Link
                          key={item.ServiceID}
                          onClick={() => {
                            window.scrollTo(0, 0);
                          }}
                          to={`services/${item.url}`}
                        >
                          {" "}
                          <div
                            key={item.ServiceID}
                            className="solutionsDiv_MegaMenu"
                          >
                            <div className="imageDiv_Solutions_MegaMenu">
                              <img src={item.image} alt={item.ServiceName} />
                            </div>
                            <div className="contentDiv_Solutions_MegaMenu">
                              <h1>{item.ServiceName} </h1>
                              <p>{item.para}</p>
                            </div>
                          </div>
                        </Link>
                      </>
                    );
                  })}
                </div>
                {/* Marketing Solutions */}
              </li>
              <li
                onClick={() => {
                  if (
                    classNameNavIndustry === "navMenuDivHide" ||
                    classNameMegaMenu === "marketingSolutions_MegaMenu_View"
                  ) {
                    setClassNameNavIndustry("navMenuDivView");
                    setClassNameMegaMenu("marketingSolutions_MegaMenu_Hide");
                    window.addEventListener("click", function (e) {
                      if (
                        e.target !==
                          document.getElementById("smallMenuIndustry") &&
                        e.target !==
                          document.getElementById("listItem_smallMenu_Industry")
                      ) {
                        setClassNameNavIndustry("navMenuDivHide");
                      }
                    });
                  } else {
                    setClassNameNavIndustry("navMenuDivHide");
                    window.removeEventListener("click", function (e) {});
                  }
                }}
              >
                <Link id="listItem_smallMenu_Industry">
                  Solutions By Industries
                  <i className="fa-solid fa-angle-down"></i>
                </Link>
                <div id="smallMenuIndustry" className={classNameNavIndustry}>
                  <ul>
                    <li>
                      <Link
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                        to={"/woodworkingindustry"}
                      >
                        Wood Working Machinery Industry
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                        to={"/spiceprocessingindustry"}
                      >
                        Spice Making Machinery Industry
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                        to={"/contact"}
                      >
                        Be the First Company in Your Industry{" "}
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={"/updates"}
                >
                  Updates
                </Link>
              </li>

              <li>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={"/about"}
                >
                  About
                </Link>
              </li>
            </ul>
          </div>
          <div className="contactSalesDiv_Navbar">
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
                setClassNameMobileMenu("mobileMenu_Hide_Navbar");
                setClassNameMobileMenuIcon("fa-solid fa-bars fa-sm fa-xl");
              }}
              to={"/contact"}
            >
              <button className="contactSalesBTN_Navbar">Contact Sales</button>
            </Link>
          </div>
          <div className="mobileMenu_Navbar">
            <i
              onClick={() => {
                if (classNameMobileMenu === "mobileMenu_View_Navbar") {
                  setClassNameMobileMenu("mobileMenu_Hide_Navbar");
                  setClassNameMobileMenuIcon("fa-solid fa-bars fa-sm fa-xl");
                  document.body.style.overflow = "auto";
                } else {
                  setClassNameMobileMenu("mobileMenu_View_Navbar");
                  document.body.style.overflow = "hidden";
                  setClassNameMobileMenuIcon("fa-solid fa-xmark fa-xl");
                }
              }}
              className={classNameMobileMenuIcon}
            ></i>
          </div>
        </div>
      </section>
      <section className={classNameMobileMenu}>
        <div className="mainDivisionMobileMenu">
          <ul>
            <li>Welcome to X360 Marketing</li>
            <li>
              <Link
                to={"/"}
                onClick={() => {
                  window.scrollTo(0, 0);
                  setClassNameMobileMenu("mobileMenu_Hide_Navbar");
                  setClassNameMobileMenuIcon("fa-solid fa-bars fa-sm fa-xl");
                  document.body.style.overflow = "auto";
                }}
              >
                Homepage
              </Link>
            </li>
            <li>
              Marketing Solutions{" "}
              <i className="fa-solid fa-arrow-right-long"></i>
            </li>
            {dataPro.map((item, key) => {
              return (
                <>
                  <li key={item.ServiceID}>
                    <Link
                      onClick={() => {
                        window.scrollTo(0, 0);
                        setClassNameMobileMenu("mobileMenu_Hide_Navbar");
                        setClassNameMobileMenuIcon(
                          "fa-solid fa-bars fa-sm fa-xl"
                        );
                        document.body.style.overflow = "auto";
                      }}
                      to={`/services/${item.url}`}
                    >
                      {item.ServiceName}
                    </Link>
                  </li>
                </>
              );
            })}
            <li>
              Solutions By Industries{" "}
              <i classname="fa-solid fa-arrow-right-long"></i>
            </li>
            <li>
              <Link
                to={"/woodworkingindustry"}
                onClick={() => {
                  window.scrollTo(0, 0);
                  setClassNameMobileMenu("mobileMenu_Hide_Navbar");
                  setClassNameMobileMenuIcon("fa-solid fa-bars fa-sm fa-xl");
                  document.body.style.overflow = "auto";
                }}
              >
                Wood Working Machinery Industry
              </Link>
            </li>{" "}
            <li>
              <Link
                to={"/spiceprocessingindustry"}
                onClick={() => {
                  window.scrollTo(0, 0);
                  setClassNameMobileMenu("mobileMenu_Hide_Navbar");
                  setClassNameMobileMenuIcon("fa-solid fa-bars fa-sm fa-xl");
                  document.body.style.overflow = "auto";
                }}
              >
                Spice Making Machinery Industry
              </Link>
            </li>{" "}
            <li>
              <Link
                to={"/contact"}
                onClick={() => {
                  window.scrollTo(0, 0);
                  setClassNameMobileMenu("mobileMenu_Hide_Navbar");
                  setClassNameMobileMenuIcon("fa-solid fa-bars fa-sm fa-xl");
                  document.body.style.overflow = "auto";
                }}
              >
                Be the First in Your Industry
              </Link>
            </li>
            <li>
              <Link
                to={"/updates"}
                onClick={() => {
                  window.scrollTo(0, 0);
                  setClassNameMobileMenu("mobileMenu_Hide_Navbar");
                  setClassNameMobileMenuIcon("fa-solid fa-bars fa-sm fa-xl");
                  document.body.style.overflow = "auto";
                }}
              >
                Updates
              </Link>
            </li>
            <li>
              <Link
                to={"/contact"}
                onClick={() => {
                  window.scrollTo(0, 0);
                  setClassNameMobileMenu("mobileMenu_Hide_Navbar");
                  setClassNameMobileMenuIcon("fa-solid fa-bars fa-sm fa-xl");
                  document.body.style.overflow = "auto";
                }}
              >
                Contact{" "}
              </Link>
            </li>
            <li>
              <Link
                to={"/about"}
                onClick={() => {
                  window.scrollTo(0, 0);
                  setClassNameMobileMenu("mobileMenu_Hide_Navbar");
                  setClassNameMobileMenuIcon("fa-solid fa-bars fa-sm fa-xl");
                  document.body.style.overflow = "auto";
                }}
              >
                About{" "}
              </Link>
            </li>
          </ul>

          <div className="contactDiv_MobileMenu">
            <h4>Contact Details :-</h4>
            <h1>X360 Marketing</h1>
            <p>Plot No. H726B, Road No. 9F2, Vishwakarma Industrial Area</p>
            <p>Jaipur, Rajasthan, India </p>
            <span className="span_FollowUs_MobileMenu">Follow Us On </span>{" "}
            <div className="socialMediaHandles">
              <a
                target="blank"
                href={"https://www.instagram.com/x360marketing/"}
              >
                {" "}
                <i className="fa-brands fa-instagram fa-xl"></i>{" "}
              </a>
              <a
                href="https://www.instagram.com/x360marketing/"
                target="blank"
                to={"/"}
              >
                {" "}
                <i className="fa-brands fa-facebook fa-xl"></i>{" "}
              </a>
              <a href="https://wa.me/918503886992" target="blank" to={"/"}>
                {" "}
                <i className="fa-brands fa-whatsapp fa-xl"></i>{" "}
              </a>
              <a
                href="https://www.linkedin.com/company/x360-marketing"
                target="blank"
                to={"/"}
              >
                {" "}
                <i className="fa-brands fa-linkedin fa-xl"></i>{" "}
              </a>
              <a
                href="https://www.linkedin.com/company/x360-marketing"
                target="blank"
                to={"/"}
              >
                {" "}
                <i className="fa-brands fa-pinterest fa-xl"></i>{" "}
              </a>
              <a
                href="https://www.youtube.com/channel/UCwa1EJei3hw1OZfIrUL4e2g"
                target="blank"
                to={"/"}
              >
                {" "}
                <i className="fa-brands fa-youtube fa-xl"></i>{" "}
              </a>
            </div>
            <span>
              Contact No.: <a href="tel:8503886992">8503886992</a>,{" "}
              <a href="tel:7849912491">7849912491</a>
            </span>
            <span>
              Email:{" "}
              <a href="mailto:sales@x360marketing.com">
                sales@x360marketing.com
              </a>
            </span>
          </div>
        </div>
      </section>
    </>
  );
}
