import React, { useEffect, useState } from "react";
import "./updates.css";
import Hero from "../Components/Hero";
import HeroImage from "../HeroImagesServices/UpdatesBGImage.png";
import { BarLoader } from "react-spinners";
export default function Updates() { const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);
  return (
    <>
     {loading ? (
        <section
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "85vh",
          }}
        >
          {" "}
          <BarLoader
            color={"#f25a2c"}
            loading={loading}
            width={200}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </section>
      ) : (
      <section className="Blogs_Updates">
      <Hero
        subHeading="WELCOME TO UPDATES PAGE"
        Heading_Blue="Stay Ahead with Our"
        Heading_Orange="Digital Marketing Insights"
        Sup_Para="Dive into our blog hub where we share actionable insights, industry trends, and expert tips to supercharge your marketing game. From SEO strategies to social media hacks, our curated content delivers valuable resources tailored to fuel your brand's success. Stay informed, stay ahead, and unlock the potential of your digital presence with our diverse array of informative articles."
        Image={HeroImage}
      />
        <div className="mainDivisionBlogsUpdates">
          <h1>This Page is Coming Soon</h1>
        </div>
      </section>
            )}

    </>
  );
}
