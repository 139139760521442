import React from "react";
import "./marketingConsulting.css";

export default function MarketingConsulting() {
  return (
    <>
      <section className="MarketingConsultingSection">
        <div className="mainDivision_MarketingConsultingSection">
          <div className="contentDiv_consultation">
            <h3>Book Now Free Consulting Service </h3>
            <h1>
              Expert Marketing <span>Consulting</span> and <span>Planing </span>
              Service
            </h1>
            <p>
              Unlock Business Potential with Our Marketing Consulting and
              Planning Services for Small and Medium Enterprises Globally.
              Tailored Strategies, Expert Guidance, and Proven Solutions to
              Propel Growth and Success in the Competitive Market Landscape.
              Partner with Us for Your Business Expansion and Sustainable Growth
              Goals.
            </p>
            <div className="buttonDiv_consultation">
              <a href="tel:8503886992">
                <button className="btnConsultation">Call Now</button>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
