import React, { useEffect, useState } from "react";
import Hero from "../Components/Hero";
import HeadingPlusPara from "../Components/HeadingPlusPara";
import { Link } from "react-router-dom";
import HeroImage from "../Images/masalamakingindustry_HeroImage.png";
import marketResearchImage from "../Assets/marketResearchImage.png";
import strategiesImage from "../Assets/strategiesImage.png";
import resultsImage from "../Assets/resultsImage.png";
import imageSKIndustries from "../Images/spiceMakingMachinery_Industry.jpg";
import { BarLoader } from "react-spinners";
export default function SpiceMachineryIndustry() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);
  return (
    <>
      {loading ? (
        <section
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "85vh",
          }}
        >
          {" "}
          <BarLoader
            color={"#f25a2c"}
            loading={loading}
            width={200}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </section>
      ) : (
        <section>
          <section className="WoodWorkingIndustry_Work">
            <Hero
              subHeading="X360 MARKETING CLIENTS"
              Heading_Blue="Elevate Your Spice Making Machinery Business With Our "
              Heading_Orange="Marketing Solution"
              Sup_Para="Elevate your spice making machinery business with our tailored marketing solutions. From enhancing brand visibility to generating leads, we're committed to helping you achieve your objectives and stand out in the market."
              Image={HeroImage}
            />
            <HeadingPlusPara
              Heading="Our Spice Making Machinery Industry Client"
              SupportiveParagraph="Our Spice Making Machinery Industry client has thrived with our digital marketing expertise tailored for machinery manufacturers. With our strategic approach, they've elevated their brand visibility, driving growth and success in their market.

          "
            />
            <div className="mainDivision_WoodWorkingIndustry_Work">
              <div className="contentDiv_MachMart_WoodWorkingIndustry">
                <h1>SK Industries</h1>
                <h3>Spice Processing Machinery Manufacturers</h3>
                <div className="socialMediaHandles  ">
                  <a
                    target="blank"
                    href="https://www.instagram.com/skindustries726/"
                  >
                    {" "}
                    <i className="fa-brands fa-instagram fa-xl"></i>{" "}
                  </a>
                  <a
                    target="blank"
                    href="https://www.instagram.com/skindustries726/"
                  >
                    {" "}
                    <i className="fa-brands fa-facebook fa-xl"></i>{" "}
                  </a>
                  <a target="blank" href="https://wa.me/918239148693">
                    {" "}
                    <i className="fa-brands fa-whatsapp fa-xl"></i>{" "}
                  </a>
                  <Link>
                    {" "}
                    <i className="fa-brands fa-linkedin fa-xl"></i>{" "}
                  </Link>
                  <Link>
                    {" "}
                    <i className="fa-brands fa-pinterest fa-xl"></i>{" "}
                  </Link>
                  <a
                    target="blank"
                    href="https://www.youtube.com/channel/UClirwNLybOJrSDPTwdtaV9g"
                  >
                    {" "}
                    <i className="fa-brands fa-youtube fa-xl"></i>{" "}
                  </a>
                </div>
                <span>Jaipur, Rajasthan</span>
                <span>From Owner</span>
                <p>
                  {" "}
                  SK Industries has witnessed remarkable progress in sales,
                  brand value, and online presence since teaming up with X360
                  Marketing. Through strategic digital marketing solutions, SK
                  Industries has broadened its reach, captivated new clientele,
                  and cemented its status as an industry frontrunner.
                  <li>
                    Attained significant sales growth and heightened brand value
                    with the backing of X360 Marketing.
                  </li>
                  <li>
                    Noticed a considerable uptick in online visibility and
                    industry recognition.
                  </li>
                  <li>
                    Employed strategic digital marketing solutions to
                    effectively allure new customers.
                  </li>
                  <li>
                    Positioned itself as an industry pacesetter through tailored
                    marketing strategies from X360 Marketing.
                  </li>
                </p>
                <div className="btnDiv_machMartWork">
                  <a target="blank" href="https://www.machmart.net/">
                    <button className="machMartWorkBTN_Hero">
                      Go to Website
                    </button>{" "}
                  </a>
                </div>
              </div>
              <div className="imageDiv_MachMart_WoodWorkingIndustry">
                <img
                  src={imageSKIndustries}
                  alt="Mach Mart Wood Working Machinery Manufacturers"
                />
              </div>
            </div>
          </section>
          {/* How We Work Section */}
          <section className="HowWeWork_Section">
            <div className="mainDivision_HowWeWorkSection">
              <div className="contentDiv_HowWeWork">
                <h3>HOW WE WORK</h3>
                <h1>
                  Unlock Success With X360 Marketing, How We Deliver Exceptional
                  Results
                </h1>
                <p>
                  At X360 Marketing, we're committed to unlocking success for
                  our clients through tailored digital marketing solutions. With
                  our expertise in digital marketing, we utilize innovative
                  strategies to drive results that exceed expectations. From
                  comprehensive digital marketing campaigns to targeted
                  advertising and SEO optimization, we work tirelessly to
                  elevate your brand's online presence and drive growth.
                  Discover how we work to deliver exceptional results and propel
                  your business forward in the digital age.
                </p>

                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={"/about"}
                >
                  {" "}
                  <button className="learnMoreBTN_HowWeWorkDiv">
                    Learn More
                  </button>
                </Link>
              </div>
              <div className="bulletPoints_HowWeWork">
                <div className="contentDivs_BulletPoints">
                  <div className="headingPlusIconDiv">
                    <img
                      src={marketResearchImage}
                      alt="X360 Marketing Market Search"
                    />{" "}
                    <h2>
                      {" "}
                      01. Strategic Planning: Crafting Tailored Digital
                      Marketing Strategies
                    </h2>
                  </div>
                  <div className="paragraphDiv_ContentDiv_BulletPoints">
                    {" "}
                    <p>
                      {" "}
                      At X360 Marketing, strategic planning is the cornerstone
                      of our approach. We start by thoroughly understanding your
                      business, industry, and target audience. Then, we craft
                      customized digital marketing strategies designed to
                      achieve your specific goals. Whether it's increasing brand
                      awareness, driving website traffic, or boosting
                      conversions, our strategic planning ensures that every
                      action aligns with your objectives.
                    </p>
                  </div>
                </div>
                <div className="contentDivs_BulletPoints">
                  <div className="headingPlusIconDiv">
                    {" "}
                    <img
                      src={strategiesImage}
                      alt="X360 Marketing Market Search"
                    />{" "}
                    <h2>
                      02. Implementation and Execution: Bringing Your Digital
                      Marketing Vision to Life
                    </h2>
                  </div>
                  <div className="paragraphDiv_ContentDiv_BulletPoints">
                    {" "}
                    <p>
                      {" "}
                      With a solid strategy in place, we move into action. Our
                      team of experts handles every aspect of implementation and
                      execution, from creating compelling content to managing
                      advertising campaigns and optimizing website performance.
                      We leverage the latest tools and technologies to ensure
                      seamless execution, keeping you informed and involved
                      every step of the way.
                    </p>
                  </div>
                </div>
                <div className="contentDivs_BulletPoints">
                  <div className="headingPlusIconDiv">
                    <img
                      src={resultsImage}
                      alt="X360 Marketing Market Search"
                    />{" "}
                    <h2>
                      {" "}
                      03. Ongoing Optimization: Fine-Tuning and Maximizing Your
                      Digital Marketing Performance
                    </h2>{" "}
                  </div>
                  <div className="paragraphDiv_ContentDiv_BulletPoints">
                    {" "}
                    <p>
                      Digital marketing is not a one-time effort; it requires
                      constant monitoring and optimization to achieve optimal
                      results. At X360 Marketing, we continuously analyze data,
                      track performance metrics, and refine our strategies to
                      maximize your digital marketing performance. Whether it's
                      adjusting ad targeting, tweaking website content, or
                      exploring new opportunities, we're dedicated to keeping
                      your digital marketing efforts on the path to success.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* End of How We Work Section */}
        </section>
      )}
    </>
  );
}
