import React from "react";
import "./clients.css";
import HeadingPlusPara from "./HeadingPlusPara";
import shivrajLogo from "../Assets/ShivrajLogo.png";
import machMartLogo from "../Assets/machMartLogo.png";
import skLogo from "../Assets/skLogo.png";
import { Link } from "react-router-dom";

export default function Clients() {
  return (
    <>
      <section className="ClientsSection">
        <HeadingPlusPara
          Heading="Our Clients"
          SupportiveParagraph="X360 Marketing elevates brands with expert digital marketing and branding services, driving growth and success for our valued clients worldwide."
        />
        <div className="mainDivision_ClientsSection">
          <Link
            onClick={() => {
              window.scrollTo(0, 0);
            }}
            to={"/woodworkingindustry"}
          >
            {" "}
            <img src={shivrajLogo} alt="Shivraj Logo" />{" "}
          </Link>
          <Link
            onClick={() => {
              window.scrollTo(0, 0);
            }}
            to={"/woodworkingindustry"}
          >
            {" "}
            <img src={machMartLogo} alt="Mach Mart Logo" />{" "}
          </Link>
          <Link
            onClick={() => {
              window.scrollTo(0, 0);
            }}
            to={"/spiceprocessingindustry"}
          >
            {" "}
            <img src={skLogo} alt="Sk Industries Logo" />{" "}
          </Link>
        </div>
      </section>
    </>
  );
}
