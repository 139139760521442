import React from "react";
import "./whyChooseUs.css";
import cusPNG from "../Images/customizablePNG.png";
import fastPNG from "../Images/fastPerformancePNG.png";
import featuredPNG from "../Images/featuredPNG.png";
import leadsGenePNG from "../Images/leadsGeneration.png";
//
export default function whyChooseUs() {
  return (
    <>
      <section className="WhyChooseUsSection">
        <div className="mainDivisionWhyChooseUs">
          <div className="headingsDivisionWhyChooseUs">
            <h4>WHY CHOOSE US</h4>
            <h1>Build a Website That Your Customers Love</h1>
            <p>
              Craft a website that resonates with your audience, offering
              seamless navigation, captivating design, and valuable content. By
              prioritizing user experience and meeting customer needs, you'll
              create a digital platform that fosters trust and drives
              engagement.
            </p>
          </div>
          <div className="contentDiv_WhyChooseUs">
            <div className="whyUsContentDiv">
              <img src={cusPNG} alt="Customizable Website for Business" />
              <h2>Customizable</h2>
              <p>
                Customizable websites tailored to your needs, ensuring optimal
                performance and user satisfaction every time.
              </p>
            </div>
            <div className="whyUsContentDiv">
              <img src={fastPNG} alt="Fast Performance Website for Business" />
              <h2>Fast Performance</h2>
              <p>
                Experience lightning-fast website performance for seamless user
                experiences and improved search engine rankings.
              </p>
            </div>
            <div className="whyUsContentDiv">
              <img
                src={featuredPNG}
                alt="Fully Featured Website for Business"
              />
              <h2>Fully Featured</h2>
              <p>
                Maximize your online presence with a fully-featured website,
                equipped with all the tools for success.
              </p>
            </div>
            <div className="whyUsContentDiv">
              <img
                src={leadsGenePNG}
                alt="Leads Generation Website for Business"
              />
              <h2>Leads Generation</h2>
              <p>
                Drive growth with a leads generation website, converting
                visitors into valuable customers effortlessly.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
