import marketingconsultingImage from "../Assets/consultingIcon.png";
import digitalMarketingImage from "../Assets/digitalMarketingIcon.png";
import socialMediaMarketingImage from "../Assets/SocialMediaMarketingIcon.png";
import socialMediaManagementImage from "../Assets/socialMediaManagementIcon.png";
import leadsGenerationImage from "../Assets/leadsGenerationIcon.png";
import contentMarketingImage from "../Assets/contentMarketingIcon.png";
import websiteDevelopmentImage from "../Assets/websiteDevelopmentIcon.png";
import seoImage from "../Assets/SEOIcon.png";

// Services Importance BG Images

import impConsultingImage from "../Images/impConsultingImage.jpg";
import impDigitalMarketingImage from "../Images/impDigitalMarketingService.png";
import impSocialMediaMarketingImage from "../Images/impSocialMediaImageService.png";
import impSocialMediaManagementImage from "../Images/impSocialMediaManagementImageService.png";
import impLeadsGenerationIamge from "../Images/impGenerateLeadsImageService.png";
import impContentMarketingImage from "../Images/impContentMarketingImageService.png";
import impWebsiteDesigningImage from "../Images/impWebsiteDesigningImageService.png";
import impSEOImage from "../Images/impSEOImageService.png";

// End of Services Importance BG Images

// Services Hero BG Images

import marketingconsultingHeroBGImage from "../HeroImagesServices/MarketingConsultingBGImage.png";
import digitalMarketingHeroBGImage from "../HeroImagesServices/DigitalMarketingBGImage.png";
import socialMediaMarketingHeroBGImage from "../HeroImagesServices/SocialMediaMarketingBGImage.png";
import socialMediaManagementHeroBGImage from "../HeroImagesServices/SocialMediaManagementBGImage.png";
import leadsGenerationHeroBGImage from "../HeroImagesServices/LeadsGenerationHeroBGImage.png";
import contentMarketingHeroBGImage from "../HeroImagesServices/ContentMarketingBGImage.png";
import websiteDesigningHeroBGImage from "../HeroImagesServices/WebsiteDesigningBGImage.png";
import seoHeroBGImage from "../HeroImagesServices/SEOServiceBGImage.png";

// End of Hero BG Images

const dataPro = [
  {
    ServiceID: 1,
    ServiceName: "Marketing Consulting (Free)",
    url: "marketingconsulting",
    image: marketingconsultingImage,
    para: " Training and Support for Enhanced Marketing Capabilities    ",
    // Hero Section
    HeroBGImage: marketingconsultingHeroBGImage,
    subHeading_Hero: "X360 Marketing Consulting Service",
    Heading_Blue_Hero: "Unlock Your Potential with ",
    Heading_Orange_Hero: "Free Marketing Consulting Services",
    Sup_Para_Hero:
      "Our free marketing consulting services are designed to unlock your business's potential by providing expert guidance and insights. Our consultants work closely with you to analyze your current strategies, identify areas for improvement, and develop actionable plans to achieve your goals.",
    // End of Hero Section

    // Importance of Website
    impServiceImage: impConsultingImage,
    impHeadingMain:
      "Transforming Your Strategy: Why Marketing Consulting Services Are Vital?",
    impParaMain:
      "Transform your marketing strategy with our consulting services, vital for staying ahead in today's dynamic market.",
    impbPoint_1:
      "In-depth analysis of current marketing strategies and performance.",
    impbPoint_2:
      "Identification of strengths, weaknesses, opportunities, and threats (SWOT analysis).",
    impbPoint_3:
      "Development of customized marketing plans and strategies aligned with business goals.",
    impbPoint_4:
      "Implementation of marketing best practices and industry trends.",
    impbPoint_5:
      "Training and support for in-house marketing teams to enhance skills and capabilities.",
    impbPoint_6:
      "Collaboration with stakeholders and leadership to gain buy-in and alignment.",
    impbPoint_7:
      "Measurement and tracking of key performance indicators (KPIs) to gauge success.",
    impbPoint_8:
      "Ongoing support and guidance to adapt to changing market conditions and opportunities.",

    // End of Importance of Website
  },
  {
    ServiceID: 2,
    ServiceName: "Digital Marketing",
    url: "digitalmarketing",
    image: digitalMarketingImage,
    para: " Boosting Brand Visibility with Comprehensive Marketing Strategies    ",
    // Hero Section
    HeroBGImage: digitalMarketingHeroBGImage,
    subHeading_Hero: "Digital Marketing Service",
    Heading_Blue_Hero: "Maximize Your Online Presence with ",
    Heading_Orange_Hero: "Expert Digital Marketing Services",
    Sup_Para_Hero:
      "Elevate your brand with our expert digital marketing services tailored to your unique needs. From search engine optimization to social media marketing, our comprehensive solutions are designed to increase your online visibility, drive traffic, and boost conversions.",
    // End of Hero Section
    // Importance of Website
    impServiceImage: impDigitalMarketingImage,
    impHeadingMain:
      "Discover Why You Need Digital Marketing Solutions for Your Business Success",
    impParaMain:
      "Explore the necessity of digital marketing solutions for achieving business success. Our comprehensive strategies increase brand awareness, drive traffic, and boost conversions, ensuring your business thrives in the digital landscape.",
    impbPoint_1:
      "Comprehensive strategies to increase brand visibility and awareness.    ",
    impbPoint_2:
      "Targeted campaigns to reach your ideal audience and drive traffic.",
    impbPoint_3:
      "Optimization of digital assets for improved search engine rankings.",
    impbPoint_4:
      "Utilization of social media platforms to engage with your audience.",
    impbPoint_5:
      "Email marketing campaigns to nurture leads and drive conversions.",
    impbPoint_6:
      "Content creation and distribution to establish thought leadership.",
    impbPoint_7:
      "Measurement and analysis of campaign performance for continuous improvement.",
    impbPoint_8:
      "Integration of marketing automation tools for streamlined workflows and efficiency.",

    // End of Importance of Website
  },
  {
    ServiceID: 3,
    ServiceName: "Social Media Marketing",
    url: "socialmediamarketing",
    image: socialMediaMarketingImage,
    para: " Managing and Monitoring Social Channels for Consistency    ",
    // Hero Section
    HeroBGImage: socialMediaMarketingHeroBGImage,
    subHeading_Hero: "Social Media Marketing",
    Heading_Blue_Hero: "Boost Your Brand's Online Presence with ",

    Heading_Orange_Hero: "Strategic Social Media Marketing Solutions",
    Sup_Para_Hero:
      "Boost your brand's online presence with our strategic social media marketing solutions. Our team of experts will develop customized strategies to engage your target audience, increase brand awareness, and drive meaningful interactions on social media platforms.",
    // End of Hero Section
    // Importance of Website
    impServiceImage: impSocialMediaMarketingImage,
    impHeadingMain:
      "Maximize Your Reach: Understanding the Power of Social Media Marketing",
    impParaMain:
      "Maximize your brand's reach and engagement with our social media marketing solutions. Our expert strategies focus on leveraging the power of social media platforms to increase brand visibility, drive traffic, and boost conversions.",
    impbPoint_1:
      "Creation of engaging content optimized for each social media platform.    ",
    impbPoint_2:
      "Development of targeted advertising campaigns to reach specific demographics.",
    impbPoint_3:
      "Management of social media channels to maintain brand consistency and engagement. ",
    impbPoint_4:
      "Monitoring of social media conversations and sentiment to inform strategy.",
    impbPoint_5:
      "Implementation of social listening tools to identify trends and opportunities. ",
    impbPoint_6:
      "Collaboration with influencers and brand advocates to expand reach and credibility. ",
    impbPoint_7:
      "Analysis of social media performance to track ROI and inform future strategy. ",
    impbPoint_8:
      "Integration of social media marketing with overall digital marketing efforts for cohesive messaging.",

    // End of Importance of Website
  },
  {
    ServiceID: 4,
    ServiceName: "Social Media Management",
    url: "socialmediamanagement",
    image: socialMediaManagementImage,
    para: "Devising Tailored Social Media Strategies for Growth    ",
    // Hero Section
    HeroBGImage: socialMediaManagementHeroBGImage,
    subHeading_Hero: "Social Media Management",
    Heading_Blue_Hero: "Enhance Your Online Presence with ",
    Heading_Orange_Hero: "Professional Social Media Management Solutions",
    Sup_Para_Hero:
      "Enhance your online presence with our professional social media management solutions. Our dedicated team will handle all aspects of your social media accounts, ensuring consistent branding, engaging content, and meaningful interactions with your audience.",
    // End of Hero Section

    // Importance of Website
    impServiceImage: impSocialMediaManagementImage,
    impHeadingMain:
      "Enhancing Your Presence: The Benefits of Social Media Management Solutions",
    impParaMain:
      "Enhance your online presence with our social media management solutions, essential for effectively managing and optimizing your social media channels. Our expert strategies focus on increasing brand visibility, driving engagement, and attracting more customers.",
    impbPoint_1:
      "Development of customized social media strategies aligned with business objectives.",
    impbPoint_2:
      "Creation and scheduling of engaging content across multiple social media platforms.",
    impbPoint_3:
      "Monitoring of social media channels for brand mentions, comments, and messages. ",
    impbPoint_4:
      "Community management to respond to inquiries, comments, and feedback promptly. ",
    impbPoint_5:
      "Analysis of social media performance metrics to track engagement, reach, and conversions. ",
    impbPoint_6:
      "Optimization of social media profiles for maximum visibility and brand consistency. ",
    impbPoint_7:
      "Collaboration with influencers and brand ambassadors to expand reach and credibility.",
    impbPoint_8:
      "Implementation of social media advertising campaigns to amplify reach and achieve specific goals.    ",

    // End of Importance of Website
  },
  {
    ServiceID: 5,
    ServiceName: "Leads Generation",
    url: "leadsgeneration",
    image: leadsGenerationImage,
    para: "Attracting High-Quality Leads with Effective Strategies    ",
    // Hero Section
    HeroBGImage: leadsGenerationHeroBGImage,
    subHeading_Hero: "Leads Generation Marketing",
    Heading_Blue_Hero: "Drive Growth with Targeted ",
    Heading_Orange_Hero: "Leads Generation Solutions for Your Business",
    Sup_Para_Hero:
      "Drive growth for your business with our targeted leads generation solutions. Using advanced techniques and strategies, we'll help you attract high-quality leads, nurture them through the sales funnel, and convert them into loyal customers.",
    // End of Hero Section
    // Importance of Website
    impServiceImage: impLeadsGenerationIamge,
    impHeadingMain:
      "Driving Growth: The Impact of Leads Generation Solutions on Your Business",
    impParaMain:
      "Drive business growth with our leads generation solutions designed to attract high-quality leads and increase revenue.",
    impbPoint_1:
      "Development of targeted lead generation campaigns to attract potential customers.",
    impbPoint_2:
      "Development of targeted lead generation campaigns potential customers. ",
    impbPoint_3:
      "Utilization of lead scoring models to prioritize and qualify leads.",
    impbPoint_4:
      "Integration of CRM systems for lead management and nurturing.",
    impbPoint_5:
      "Optimization of lead generation funnels for improved conversion rates.",
    impbPoint_6:
      "Personalization of messaging and offers to cater to individual needs.",
    impbPoint_7:
      "Measurement of lead generation performance through key metrics and analytics.",
    impbPoint_8:
      "Continuous testing and optimization to maximize lead generation efficiency.",

    // End of Importance of Website
  },
  {
    ServiceID: 6,
    ServiceName: "Content Marketing",
    url: "contentmarketing",
    image: contentMarketingImage,
    para: "Content Distribution for Increased Online Visibility    ",
    // Hero Section
    HeroBGImage: contentMarketingHeroBGImage,
    subHeading_Hero: "Content Marketing",
    Heading_Blue_Hero: "Maximize Your Reach with ",
    Heading_Orange_Hero:
      "Tailored Content Marketing Solutions for Your Business",
    Sup_Para_Hero:
      "Maximize your reach and engage your audience with our tailored content marketing solutions. Our team of experts will create compelling and relevant content that resonates with your target audience, driving traffic to your website and increasing brand awareness.",
    // End of Hero Section
    // Importance of Website
    impServiceImage: impContentMarketingImage,
    impHeadingMain:
      "Unlocking Your Potential: The Essential Role of Content Marketing Services",
    impParaMain:
      "Unlock your business potential with our content marketing services. Our tailored strategies focus on creating valuable content that ranks high on search engines, driving organic traffic and conversions.",
    impbPoint_1:
      "Creation of high-quality, engaging content tailored to your target audience.",
    impbPoint_2:
      "Development of content calendars and editorial schedules for consistency.",
    impbPoint_3:
      "Optimization of content for search engines to improve organic visibility. ",
    impbPoint_4:
      "Distribution of content across various channels to reach a wider audience.",
    impbPoint_5:
      "Integration of storytelling and brand messaging to resonate with readers.",
    impbPoint_6:
      "Measurement of content performance through analytics and KPIs.",
    impbPoint_7:
      "Regular updates and revisions to keep content relevant and up-to-date.",
    impbPoint_8:
      "Collaboration with influencers and industry experts to amplify reach and credibility.",

    // End of Importance of Website
  },
  {
    ServiceID: 7,
    ServiceName: "Website Designing",
    url: "websitedesigning",
    image: websiteDevelopmentImage,
    para: "Responsive Layouts for Seamless Browsing Across Devices",
    // Hero Section
    HeroBGImage: websiteDesigningHeroBGImage,
    subHeading_Hero: "Website Design Service",
    Heading_Blue_Hero: "Transform Your Online Presence with ",

    Heading_Orange_Hero: "Expert Website Designing Solutions for Your Business",
    Sup_Para_Hero:
      "Transform your online presence with our expert website designing solutions. From responsive design to user-friendly navigation, we'll create a visually stunning and functional website that effectively showcases your brand and converts visitors into customers.",
    // End of Hero Section
    // Importance of Website
    impServiceImage: impWebsiteDesigningImage,
    impHeadingMain:
      "Unveiling the Importance of Website Designing to Elevate Your Online Presence",
    impParaMain:
      "Discover how our website designing services can enhance your online presence. We focus on creating visually appealing and user-friendly websites that rank high on search engines, driving traffic and conversions.",
    impbPoint_1: "Visually appealing designs optimized for user experience.  ",
    impbPoint_2: "Responsive layouts for seamless browsing on all devices.  ",
    impbPoint_3:
      "Integration of SEO best practices for improved search engine rankings.  ",
    impbPoint_4:
      "Customized solutions tailored to your brand's unique needs.  ",
    impbPoint_5:
      "High-quality graphics and multimedia elements to enhance engagement.  ",
    impbPoint_6:
      "Fast loading speeds for improved user satisfaction and retention.  ",
    impbPoint_7:
      "Clear navigation and intuitive user interface for easy browsing.  ",
    impbPoint_8:
      "Integration of analytics tools to track website performance and make data-driven improvements.  ",

    // End of Importance of Website
  },
  {
    ServiceID: 8,
    ServiceName: "SEO Service",
    url: "searchengineoptimization",
    image: seoImage,
    para: "Technical SEO Audits for Website Optimization",
    // Hero Section
    HeroBGImage: seoHeroBGImage,
    subHeading_Hero: "SEO Service",
    Heading_Blue_Hero: "Dominate Search Results with ",

    Heading_Orange_Hero:
      "Proven Search Engine Optimization Solutions for Your Business",
    Sup_Para_Hero:
      "Dominate search engine results and increase your online visibility with our proven search engine optimization solutions. Our team of SEO experts will optimize your website and content to improve your search engine rankings, driving organic traffic and generating valuable leads.",
    // End of Hero Section
    // Importance of Website
    impServiceImage: impSEOImage,
    impHeadingMain:
      "Dominate Search Results: The Significance of SEO Services for Your Business",
    impParaMain:
      "Dominate search engine results and increase your online visibility with our SEO services. Our proven strategies focus on optimizing your website and content to rank high on search engines, driving organic traffic and boosting conversions.",
    impbPoint_1:
      "Keyword research and optimization to improve search engine rankings. ",
    impbPoint_2:
      "On-page optimization of website content, meta tags, and headings. ",
    impbPoint_3:
      "Off-page optimization through link building and content promotion. ",
    impbPoint_4:
      "Technical SEO audits to identify and address website issues impacting search visibility. ",
    impbPoint_5:
      "Local SEO strategies to improve visibility in geographic search results. ",
    impbPoint_6:
      "Local SEO strategies to improve visibility in geographic search results. ",
    impbPoint_7:
      "Integration of SEO with content marketing and social media efforts for holistic approach. ",
    impbPoint_8:
      "Reporting and analysis of SEO performance metrics to track progress and ROI.",

    // End of Importance of Website
  },
];

export default dataPro;
