import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Home from "./Pages/Home";
import About from "./Pages/About";
import WoodWorkingIndustry from "./Pages/WoodWorkingIndustry";
import SpiceMachineryIndustry from "./Pages/SpiceMachineryIndustry";
import MarketingConsulting from "./Components/MarketingConsulting";
import Contact from "./Pages/Contact";
import ServicesPage from "./Pages/ServicesPage";
import Updates from "./Pages/Updates";

function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route
            path="/woodworkingindustry"
            element={<WoodWorkingIndustry />}
          />
          <Route
            path="/spiceprocessingindustry"
            element={<SpiceMachineryIndustry />}
          />
          <Route path="/contact" element={<Contact />} />
          <Route path="/updates" element={<Updates />} />

          <Route path="/services">
            <Route path=":serviceName" element={<ServicesPage />} />
          </Route>
        </Routes>
        <MarketingConsulting />
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
