import React from "react";
import "./hero.css";
import { Link } from "react-router-dom";

export default function Hero(props) {
  return (
    <>
      <section className="HeroSection">
        <div className="mainDivision_HeroSection">
          <div className="contentDiv_HeroSection">
            <h4>{props.subHeading}</h4>
            <h1>
              {props.Heading_Blue} <span> {props.Heading_Orange} </span>
            </h1>
            <p>{props.Sup_Para}</p>

            <div className="socialMediaHandles">
              <a
                target="blank"
                href={"https://www.instagram.com/x360marketing/"}
              >
                {" "}
                <i className="fa-brands fa-instagram fa-xl"></i>{" "}
              </a>
              <a
                href="https://www.instagram.com/x360marketing/"
                target="blank"
                to={"/"}
              >
                {" "}
                <i className="fa-brands fa-facebook fa-xl"></i>{" "}
              </a>
              <a href="https://wa.me/918503886992" target="blank" to={"/"}>
                {" "}
                <i className="fa-brands fa-whatsapp fa-xl"></i>{" "}
              </a>
              <a
                href="https://www.linkedin.com/company/x360-marketing"
                target="blank"
                to={"/"}
              >
                {" "}
                <i className="fa-brands fa-linkedin fa-xl"></i>{" "}
              </a>
              <a
                href="https://www.linkedin.com/company/x360-marketing"
                target="blank"
                to={"/"}
              >
                {" "}
                <i className="fa-brands fa-pinterest fa-xl"></i>{" "}
              </a>
              <a
                href="https://www.youtube.com/channel/UCwa1EJei3hw1OZfIrUL4e2g"
                target="blank"
                to={"/"}
              >
                {" "}
                <i className="fa-brands fa-youtube fa-xl"></i>{" "}
              </a>
            </div>

            <div className="btnDiv_Hero">
              <Link
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                to={"/contact"}
              >
                <button className="learnMoreBTN_Hero">Contact Us</button>{" "}
              </Link>
            </div>
          </div>
          <div className="imageDiv_HeroSection">
            <img src={props.Image} alt="X360 Digital Marketing Service" />
          </div>
        </div>
      </section>
    </>
  );
}
