import React from "react";
import "./headingPlusPara.css";

export default function HeadingPlusPara(props) {
  return (
    <>
      <section className="HeadingPlusPara">
        <h3>{props.Heading}</h3>
        <hr />
        <p>{props.SupportiveParagraph}</p>
      </section>
    </>
  );
}
