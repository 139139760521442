import React, { useEffect, useState } from "react";
import { BarLoader } from "react-spinners";
import Hero from "../Components/Hero";
import bgImage from "../Images/contactUsHeroImage.png";
import Location from "../Components/Location";

export default function Contact() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);
  return (
    <>
      {loading ? (
        <section
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "85vh",
          }}
        >
          {" "}
          <BarLoader
            color={"#f25a2c"}
            loading={loading}
            width={200}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </section>
      ) : (
        <section>
          {" "}
          <Hero
            subHeading="CONTACT DETAILS OF  X360 MARKETING"
            Heading_Blue="Get in Touch With X360 Marketing, Contact Us For"
            Heading_Orange="Expert Marketing Solution"
            Sup_Para="Elevate your brand with expert marketing solutions from X360 Marketing. Contact us today to unlock the full potential of your digital presence and take your business to new heights in the competitive marketplace."
            Image={bgImage}
          />
          <Location />
        </section>
      )}
    </>
  );
}
