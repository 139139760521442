import React from "react";
import "./location.css";
import HeadingPlusPara from "./HeadingPlusPara";

export default function Location() {
  return (
    <>
      <section className="LocationSection">
        <HeadingPlusPara
          Heading="Our Location"
          SupportiveParagraph="Located in the heart of Rajasthan, we serve clients globally, offering expert digital marketing solutions tailored to your unique needs."
        />

        <div className="mainDivision_LocationSection">
          <div className="contentDiv_Address">
            <h3>
              <i className="fa-solid fa-location-dot"></i>Address
            </h3>
            <p>
              {" "}
              X360 Marketing <br />
              Plot No. H726B, Road No. 9F2, Vishvakarma Industrial Area Area,
              Jaipur, Rajasthan {"(302013)"}
            </p>
            <h3>
              <i className="fa-solid fa-clock"></i>Working Hours
            </h3>
            <p>Monday to Saturday {"(9AM to 6PM)"}</p>
            <p>Sunday {"(Closed)"}</p>
            <h3>
              <i className="fa-solid fa-envelope"></i>Email
            </h3>
            <p>
              <a href="mailto:sales@x360marketing.com">
                sales@x360marketing.com
              </a>{" "}
              {"(For Sales)"}{" "}
            </p>
            <p>
              {" "}
              <a href="mailto:support.x360marketing.com">
                support.x360marketing.com
              </a>{" "}
              {"(For Support)"}{" "}
            </p>
            <h3>
              <i className="fa-solid fa-phone"></i>Mobile No.
            </h3>
            <p>
              <a href="tel:8503886992">8503886992</a>,{" "}
              <a href="tel:7849912491">7849912491</a>
            </p>
          </div>
          <div className="mapDiv_Map">
            {" "}
            <iframe
              title="x360 Marketing Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3555.3095552133914!2d75.78641227544209!3d26.988755876601946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db3d4d93002db%3A0xdd6a0e9570d58f3!2sX360%20Marketing!5e0!3m2!1sen!2sin!4v1710160241293!5m2!1sen!2sin"
              width="600"
              height="450"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </section>
    </>
  );
}
