import React, { useEffect, useState } from "react";
import "./about.css";
import Hero from "../Components/Hero";
import bgImage from "../Images/aboutUs_BGImage.png";
import HeadingPlusPara from "../Components/HeadingPlusPara";
import OwnerImage from "../Images/OwnerImageNew.png";
import { Link } from "react-router-dom";
import missionImage from "../Assets/missionImage.png";
import visionImage from "../Assets/visionImage.png";
import valuesImage from "../Assets/valuesImage.png";
import goalsImage from "../Assets/goalsImage.png";
import { BarLoader } from "react-spinners";

export default function About() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);

  return (
    <>
      {loading ? (
        <section
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "85vh",
          }}
        >
          {" "}
          <BarLoader
            color={"#f25a2c"}
            loading={loading}
            width={200}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </section>
      ) : (
        <section className="AboutPageSection">
          <Hero
            subHeading="ABOUT X360 MARKETING"
            Heading_Blue="Unlock Your Brand's Potential, Welcome to "
            Heading_Orange="X360 Marketing"
            Sup_Para="Welcome to X360 Marketing, your go-to partner for unlocking your brand's potential. With tailored digital marketing solutions, we're here to elevate your online presence and help you thrive in the digital landscape."
            Image={bgImage}
          />
          <HeadingPlusPara
            Heading="About Company"
            SupportiveParagraph="X360 Marketing: Your Partner for Digital Success in Machinery Manufacturing - Elevate Your Brand, Drive Growth, and Dominate the Market."
          />
          <div className="mainDivision_AboutPage">
            <h1>About X360 Marketing</h1>
            <p>
              Welcome to X360 Marketing, founded in 2021 by owner Jitendra
              Jangid in the vibrant city Jaipur, Rajasthan. Our company is
              dedicated to empowering businesses with a comprehensive suite of
              digital marketing solutions tailored to their unique needs.
              <br /> <br />
              At X360 Marketing, we offer a wide range of services to help
              businesses thrive in the competitive digital landscape. Our expert
              team provides marketing consulting services to guide clients
              through strategic planning and execution, ensuring maximum
              effectiveness and ROI. With our digital marketing services, we
              leverage the latest tools and techniques to increase online
              visibility, drive targeted traffic, and boost conversions.
              Additionally, our content marketing solutions are designed to
              engage audiences and enhance brand authority, while our website
              design services create captivating online experiences that leave a
              lasting impression.
              <br /> <br />
              Our goal at X360 Marketing is to help businesses achieve their
              goals and surpass their expectations. We are committed to
              delivering exceptional results and providing personalized support
              every step of the way. Our dedication to excellence has earned us
              a reputation as a trusted partner for businesses across a variety
              of industries.
              <br /> <br />
              Speaking of industries, X360 Marketing serves clients from diverse
              sectors including machinery manufacturing, technology, healthcare,
              retail, and more. Regardless of the industry you're in, we have
              the expertise and resources to help you succeed in the digital
              realm.
              <br /> <br />
              In conclusion, X360 Marketing is not just a digital marketing
              agency; we're your strategic ally in achieving digital success.
              Partner with us to unlock the full potential of your business and
              take it to new heights in the digital age.
            </p>

            <div className="fromOwnerDiv">
              <div className="contentDiv_fromOwner">
                <h3>FROM OWNER</h3>
                <h2>Jitendra Jangid</h2>
                <h1>Owner of X360 Marketing</h1>
                <h4>Social Media Links</h4>
                <div className="socialMediaHandles_AboutUs">
                  <a
                    target="blank"
                    href={"https://www.instagram.com/royal_jangid492/"}
                  >
                    {" "}
                    <i className="fa-brands fa-instagram fa-xl"></i>{" "}
                  </a>
                  <a
                    href="https://www.instagram.com/royal_jangid492/"
                    target="blank"
                    to={"/"}
                  >
                    {" "}
                    <i className="fa-brands fa-facebook fa-xl"></i>{" "}
                  </a>
                  <a href="https://wa.me/917849912491" target="blank" to={"/"}>
                    {" "}
                    <i className="fa-brands fa-whatsapp fa-xl"></i>{" "}
                  </a>
                  <a
                    href="https://www.linkedin.com/in/jitendra-jangid-65371a1a1/"
                    target="blank"
                    to={"/"}
                  >
                    {" "}
                    <i className="fa-brands fa-linkedin fa-xl"></i>{" "}
                  </a>
                  <Link to="/">
                    {" "}
                    <i className="fa-brands fa-pinterest fa-xl"></i>{" "}
                  </Link>
                  <Link to={"/"}>
                    {" "}
                    <i className="fa-brands fa-youtube fa-xl"></i>{" "}
                  </Link>
                </div>
                <p>
                  At X360 Marketing, we understand the unique challenges and
                  opportunities that machinery manufacturers face in the digital
                  age. That's why we offer specialized digital marketing
                  solutions tailored specifically to meet the needs of this
                  industry. With our comprehensive services, machinery
                  manufacturers can enhance their online presence, drive
                  targeted traffic, and generate quality leads.
                  <br /> <br />
                  Our strategic approach to digital marketing for machinery
                  manufacturers includes a combination of search engine
                  optimization
                  {" (SEO)"}, content marketing, social media management, and
                  targeted advertising. By leveraging these tactics, we help our
                  clients increase brand awareness, attract potential customers,
                  and ultimately, drive sales.
                </p>
              </div>
              <div className="imageDiv_fromOwner">
                <img src={OwnerImage} alt="Owner of X360 Marketing" />
              </div>
            </div>
          </div>

          <section className="mvvg_Section">
            '{" "}
            <div className="missionVisionValuesGoals_Division">
              <div className="mvvg_divs">
                <img src={missionImage} alt="X360 Marketing Mission" />
                <h2>Mission</h2>
                <p>
                  At X360 Marketing, our mission is to provide exceptional
                  digital marketing and consulting services, specializing in
                  solutions tailored for machinery manufacturers, to drive
                  growth and success.
                </p>
              </div>
              <div className="mvvg_divs">
                <img src={visionImage} alt="X360 Marketing Mission" />
                <h2>Vision</h2>
                <p>
                  {" "}
                  X360 Marketing envisions becoming a leading provider of
                  digital marketing and consulting services, catering
                  specifically to machinery manufacturers, to achieve
                  unparalleled success and industry leadership.
                </p>
              </div>
              <div className="mvvg_divs">
                <img src={valuesImage} alt="X360 Marketing Mission" />
                <h2>Values</h2>
                <p>
                  {" "}
                  At X360 Marketing, we uphold integrity, innovation, and
                  excellence in all our endeavors, aiming to deliver
                  unparalleled value and results for our clients in the
                  machinery manufacturing sector.
                </p>
              </div>
              <div className="mvvg_divs">
                <img src={goalsImage} alt="X360 Marketing Mission" />
                <h2>Goals</h2>
                <p>
                  {" "}
                  At X360 Marketing, our goal is to empower machinery
                  manufacturers with cutting-edge digital marketing solutions,
                  driving brand visibility, lead generation, and industry
                  recognition for sustainable growth and success.
                </p>
              </div>
            </div>
          </section>
        </section>
      )}
    </>
  );
}
