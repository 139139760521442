import React, { useEffect, useState } from "react";
import WhyChooseUs from "../Components/WhyChooseUs";
import dataPro from "../Data/ServicesData";
import { Link, useParams } from "react-router-dom";
import Hero from "../Components/Hero";
import "./ServicePage.css";
import marketResearchImage from "../Assets/marketResearchImage.png";
import strategiesImage from "../Assets/strategiesImage.png";
import resultsImage from "../Assets/resultsImage.png";
import { BarLoader } from "react-spinners";

export default function ServicesPage() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);
  const { serviceName } = useParams();
  let ServicePer = dataPro.find((e) => e.url === serviceName);

  return (
    <>
     {loading ? (
        <section
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "85vh",
          }}
        >
          {" "}
          <BarLoader
            color={"#f25a2c"}
            loading={loading}
            width={200}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </section>
      ) : (
      <section className="ServicesPage">
        <Hero
          subHeading={ServicePer.subHeading_Hero}
          Heading_Blue={ServicePer.Heading_Blue_Hero}
          Heading_Orange={ServicePer.Heading_Orange_Hero}
          Sup_Para={ServicePer.Sup_Para_Hero}
          Image={ServicePer.HeroBGImage}
        />
        <section className="impofService">
          <div className="mainDivision_impofService">
            <div className="imgDivv_impService_ServiceSection">
              <img
                src={ServicePer.impServiceImage}
                alt="x360 Marketingm Best Digital Markeing Consulting Service"
              />
            </div>
            <div className="contentDiv_impService_ServiceSection">
              <h1>{ServicePer.impHeadingMain}</h1>
              <p>{ServicePer.impParaMain}</p>
              <ul>
                <li>{ServicePer.impbPoint_1}</li>
                <li>{ServicePer.impbPoint_2}</li>
                <li>{ServicePer.impbPoint_3}</li>
                <li>{ServicePer.impbPoint_4}</li>
                <li>{ServicePer.impbPoint_5}</li>
                <li>{ServicePer.impbPoint_6}</li>
                <li>{ServicePer.impbPoint_7}</li>
                <li>{ServicePer.impbPoint_8}</li>
              </ul>
            </div>
          </div>
        </section>

        <section className="criteriaofChoosingSevicePartner">
          <div className="mainDivisionCriteriaofChoosing">
            <h1>
              Essential Criteria to Choosing the Right Digital Marketing Partner
            </h1>
            <p>
              In today's fiercely competitive business environment, selecting
              the appropriate digital marketing partner holds paramount
              importance for the success of your venture. Several key criteria,
              including expertise, customization, communication, and results
              orientation, are pivotal in making a well-informed decision that
              aligns with your business goals and objectives.
            </p>
            <p>
              <span>1. Expertise and Experience:</span> Look for a digital
              marketing partner with a proven track record of success and
              extensive experience in your industry. A partner with expertise in
              various digital marketing channels, such as SEO, social media, and
              content marketing, can provide valuable insights and strategies
              tailored to your business needs.
            </p>
            <p>
              <span>2. Customized Solutions: </span> Opt for a digital marketing
              partner that offers customized solutions to meet your specific
              goals and objectives. Avoid one-size-fits-all approaches and
              prioritize partners who take the time to understand your business,
              target audience, and unique challenges to develop personalized
              strategies.
            </p>
            <p>
              <span>3. Transparent Communication: </span> Communication is key
              to a successful partnership. Choose a digital marketing partner
              that maintains open and transparent communication throughout the
              collaboration process. Regular updates, progress reports, and
              clear expectations ensure alignment and foster trust between both
              parties.
            </p>
            <p>
              <span>4. Results-Oriented Approach:</span> Ultimately, your
              digital marketing partner should be focused on delivering tangible
              results and driving measurable outcomes for your business. Look
              for partners who prioritize data-driven decision-making, track key
              performance indicators, and continuously optimize strategies to
              maximize ROI and achieve your business objectives.
            </p>
            <p>
              In summary, when selecting a digital marketing partner, consider
              factors such as expertise, customization, communication, and
              results orientation to ensure a successful and fruitful
              partnership that drives your business forward.
            </p>
          </div>
        </section>

        <WhyChooseUs />

        {/* How We Work Section */}
        <section className="HowWeWork_Section">
          <div className="mainDivision_HowWeWorkSection">
            <div className="contentDiv_HowWeWork">
              <h3>HOW WE WORK</h3>
              <h1>
                Unlock Success With X360 Marketing, How We Deliver Exceptional
                Results
              </h1>
              <p>
                At X360 Marketing, we're committed to unlocking success for our
                clients through tailored digital marketing solutions. With our
                expertise in digital marketing, we utilize innovative strategies
                to drive results that exceed expectations. From comprehensive
                digital marketing campaigns to targeted advertising and SEO
                optimization, we work tirelessly to elevate your brand's online
                presence and drive growth. Discover how we work to deliver
                exceptional results and propel your business forward in the
                digital age.
              </p>

              <Link
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                to={"/about"}
              >
                {" "}
                <button className="learnMoreBTN_HowWeWorkDiv">
                  Learn More
                </button>
              </Link>
            </div>
            <div className="bulletPoints_HowWeWork">
              <div className="contentDivs_BulletPoints">
                <div className="headingPlusIconDiv">
                  <img
                    src={marketResearchImage}
                    alt="X360 Marketing Market Search"
                  />{" "}
                  <h2>
                    {" "}
                    01. Strategic Planning: Crafting Tailored Digital Marketing
                    Strategies
                  </h2>
                </div>
                <div className="paragraphDiv_ContentDiv_BulletPoints">
                  {" "}
                  <p>
                    {" "}
                    At X360 Marketing, strategic planning is the cornerstone of
                    our approach. We start by thoroughly understanding your
                    business, industry, and target audience. Then, we craft
                    customized digital marketing strategies designed to achieve
                    your specific goals. Whether it's increasing brand
                    awareness, driving website traffic, or boosting conversions,
                    our strategic planning ensures that every action aligns with
                    your objectives.
                  </p>
                </div>
              </div>
              <div className="contentDivs_BulletPoints">
                <div className="headingPlusIconDiv">
                  {" "}
                  <img
                    src={strategiesImage}
                    alt="X360 Marketing Market Search"
                  />{" "}
                  <h2>
                    02. Implementation and Execution: Bringing Your Digital
                    Marketing Vision to Life
                  </h2>
                </div>
                <div className="paragraphDiv_ContentDiv_BulletPoints">
                  {" "}
                  <p>
                    {" "}
                    With a solid strategy in place, we move into action. Our
                    team of experts handles every aspect of implementation and
                    execution, from creating compelling content to managing
                    advertising campaigns and optimizing website performance. We
                    leverage the latest tools and technologies to ensure
                    seamless execution, keeping you informed and involved every
                    step of the way.
                  </p>
                </div>
              </div>
              <div className="contentDivs_BulletPoints">
                <div className="headingPlusIconDiv">
                  <img src={resultsImage} alt="X360 Marketing Market Search" />{" "}
                  <h2>
                    {" "}
                    03. Ongoing Optimization: Fine-Tuning and Maximizing Your
                    Digital Marketing Performance
                  </h2>{" "}
                </div>
                <div className="paragraphDiv_ContentDiv_BulletPoints">
                  {" "}
                  <p>
                    Digital marketing is not a one-time effort; it requires
                    constant monitoring and optimization to achieve optimal
                    results. At X360 Marketing, we continuously analyze data,
                    track performance metrics, and refine our strategies to
                    maximize your digital marketing performance. Whether it's
                    adjusting ad targeting, tweaking website content, or
                    exploring new opportunities, we're dedicated to keeping your
                    digital marketing efforts on the path to success.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End of How We Work Section */}
      </section>
            ) }

    </>
  );
}
